import { useState, useEffect } from 'react';
import { SectionProps } from '../../../utils/SectionProps';
import { Form } from 'react-bootstrap';
import CountUp from "react-countup";
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import BackgroundImage from "./../../../assets/overlay_tint_7d2d.png";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const CallOfDutyModernWarfare = () => {

  let location = useLocation();
  const [loss, setLoss] = useState(0);
  const [death, setDeath] = useState(0);
  const [win, setWin] = useState(0);
  const [suicide, setSuicide] = useState(0);
  const [kill, setKill] = useState(0);
  const [assist, setAssist] = useState(0);
  const [position, setPosition] = useState({});

  function updateFontColor() {
    let params = new URL(document.location).searchParams;
    let theme = params.get("theme");

    if (theme) {
      if (theme === "light") {
        return "white";
      }
      if (theme === "dark") {
        return "dark";
      }
    }
  }

  useEffect(() => {
    document.body.style.background = "none";
    Array.from(document.querySelectorAll('.form-label')).forEach(
      (el) => el.classList.remove('form-label')
    );

    const socket = io("https://dev.anygamecounter.com");

    socket.on('connect', () => {
      console.log(`connected: ${socket.id}`)
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.emit("overlay", location.search.replace("?id=", ""));

    socket.on("overlay", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        setPosition({
          "left": data.codmw_stats.page_position_left,
          "top": data.codmw_stats.page_position_top,
          "width": data.codmw_stats.page_position_width
        })
        setLoss(data.codmw_stats.losses);
        setDeath(data.codmw_stats.deaths);
        setWin(data.codmw_stats.wins);
        setSuicide(data.codmw_stats.suicides);
        setKill(data.codmw_stats.kills);
        setAssist(data.codmw_stats.assists);
      }
    })

    socket.on("callofdutymodernwarfare", (data) => {
      if (data.user === location.search.replace("?id=", "")) {
        /*setPosition({
          "left": data.codmw_stats.page_position_left,
          "top": data.codmw_stats.page_position_top,
          "width": data.codmw_stats.page_position_width
        })*/
        setLoss(data.losses);
        setDeath(data.deaths);
        setWin(data.wins);
        setSuicide(data.suicides);
        setKill(data.kills);
        setAssist(data.assists);
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='overlay-background' style={{ backgroundImage: `url(${BackgroundImage})`, left: position.left, top: position.top, width: position.width }}>
      <div style={{margin: 10}}>
        <div className="input-group">
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Losses: <CountUp start={loss} end={loss} onNewEnd={loss} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Deaths: <CountUp start={death} end={death} onNewEnd={death} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Wins: <CountUp start={win} end={win} onNewEnd={win} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Suicides: <CountUp start={suicide} end={suicide} onNewEnd={suicide} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Kills: <CountUp start={kill} end={kill} onNewEnd={kill} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Assists: <CountUp start={assist} end={assist} onNewEnd={assist} duration={1} /></Form.Label>
        </div>
      </div>
    </div>
  );
}

CallOfDutyModernWarfare.propTypes = propTypes;
CallOfDutyModernWarfare.defaultProps = defaultProps;

export default CallOfDutyModernWarfare;