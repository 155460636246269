function ToolTip(e) {
  const newTarget = e.currentTarget.id.replace("show","");
  if (document.getElementById(newTarget).style.display === "none" || document.getElementById(newTarget).style.display.length === 0) {
    document.getElementById(newTarget).style.display = "block";
  }
  else {
    document.getElementById(newTarget).style.display = "none";
  }
};

export default ToolTip;