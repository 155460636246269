import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Stats = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  const [userData, setUserData] = useState(0);
  const [userDataError, setUserDataError] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const username = location.search.replace("?","");
    fetch(`https://dev.anygamecounter.com/api/getUserStats/${username}`).then((response) => response.json()).then(data => {
      if (data["error"]) {
        setUserDataError(data);
        console.log(data)
      }
      else {
        setUserData(data);
        console.log(data)
      }
    })
  }, [location])

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <div >
                {
                  userData ? (
                    <div>
                      <h2 id={"top"} className="mt-0 mb-16">
                        <span className="text-color-primary">{userData.username.toUpperCase()}'s Public Stats</span>
                      </h2>
                    <div className="container-xs center">
                      <ul>
                        <h3 id={"7Days2Die"}>
                          <a href="https://7daystodie.com">7 Days 2 Die</a>
                        </h3>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Zombies: ${userData.sevend2d_stats.session["zombie"]}`}/>
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.sevend2d_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Zombies: ${userData.sevend2d_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.sevend2d_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"ApexLegends"}>
                          <a href="https://www.ea.com/games/apex-legends">Apex Legends</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Level: ${userData.apex_stats["level"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.apex_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Damage: ${userData.apex_stats["total_damage"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Matches: ${userData.apex_stats["total_matches"]}`} />
                          </ListItemButton>
                        </ListItem>

                        <h2 id={"ArkSurvivalEvolved"}>
                          <a href="https://store.steampowered.com/app/346110/ARK_Survival_Evolved/">Ark: Survival Evolved</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.ark_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.ark_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Tames: ${userData.ark_stats.session["tame"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Bosses: ${userData.ark_stats.session["boss"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.ark_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.ark_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Tames: ${userData.ark_stats["total_tames"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Bosses Completed: ${userData.ark_stats["total_bossescompleted"]}`} />
                          </ListItemButton>
                        </ListItem>

                        <h2 id={"CODMW"}>
                          <a href="https://www.callofduty.com/modernwarfare">Call of Duty: Modern Warfare</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losses: ${userData.codmw_stats["losses"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.codmw_stats["deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.codmw_stats["wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Suicides: ${userData.codmw_stats["suicides"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.codmw_stats["kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Assists: ${userData.codmw_stats["assists"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"CODWZ"}>
                          <a href="https://www.callofduty.com/warzone">Call of Duty: Warzone</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Games played: ${userData.codwz_stats["gamesPlayed"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.codwz_stats["deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Suicides: ${userData.codwz_stats["suicides"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.codwz_stats["kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Headshots: ${userData.codwz_stats["headshots"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losses: ${userData.codwz_stats["losses"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.codwz_stats["wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Assists: ${userData.codwz_stats["assists"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"CSGO"}>
                          <a href="https://store.steampowered.com/app/730/CounterStrike_Global_Offensive/">CS:GO</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.csgo_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.csgo_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.csgo_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.csgo_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.csgo_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.csgo_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.csgo_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.csgo_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Destiny2"}>
                          <a href="https://www.bungie.net/7/en/Destiny/NewLight">Destiny 2</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.destiny2_stats["kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.destiny2_stats["deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Assists: ${userData.destiny2_stats["assists"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Average Lifespan: ${userData.destiny2_stats["averageLifespan"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills Deaths Ratio: ${userData.destiny2_stats["killsDeathsRatio"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Activities Cleared: ${userData.destiny2_stats["activitiesCleared"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Objectives Completed: ${userData.destiny2_stats["objectivesCompleted"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Heroic Public Events Completed: ${userData.destiny2_stats["heroicPublicEventsCompleted"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Adventures Completed: ${userData.destiny2_stats["adventuresCompleted"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Public Events Completed: ${userData.destiny2_stats["publicEventsCompleted"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Fire Team Activities: ${userData.destiny2_stats["fireTeamActivities"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"EscapeFromTarkov"}>
                          <a href="https://www.escapefromtarkov.com/">Escape From Tarkov</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Bear Kills: ${userData.tarkov_stats.session["bear"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`USEC Kills: ${userData.tarkov_stats.session["usec"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Scav Kills: ${userData.tarkov_stats.session["scav"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Sniper Kills: ${userData.tarkov_stats.session["sniper"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Raider Kills: ${userData.tarkov_stats.session["raider"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Guard Kills: ${userData.tarkov_stats.session["guard"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Rogue Kills: ${userData.tarkov_stats.session["rogue"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Cultist Kills: ${userData.tarkov_stats.session["cultist"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Team Kills: ${userData.tarkov_stats.session["tk"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.tarkov_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Extracts: ${userData.tarkov_stats.session["extract"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Boss Kills: ${userData.tarkov_stats.session["boss"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Bear Kills: ${userData.tarkov_stats["total_bears"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Usec Kills: ${userData.tarkov_stats["total_usecs"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Scav Kills: ${userData.tarkov_stats["total_scavs"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Sniper Kills: ${userData.tarkov_stats["total_snipers"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Raider Kills: ${userData.tarkov_stats["total_raiders"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Guard Kills: ${userData.tarkov_stats["total_guards"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Rogue Kills: ${userData.tarkov_stats["total_rogues"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Cultist Kills: ${userData.tarkov_stats["total_cultists"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.tarkov_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.tarkov_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Extracts: ${userData.tarkov_stats["total_extracts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Team Kills: ${userData.tarkov_stats["total_tks"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Bosses Kills: ${userData.tarkov_stats["total_boss"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"FallGuys"}>
                          <a href="https://www.fallguys.com/">Fall Guys</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Victories: ${userData.fallguys_stats.session["victory"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Victories: ${userData.fallguys_stats["total_victories"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Fortnite"}>
                          <a href="https://www.epicgames.com/fortnite/en-US/download">Fortnite</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.fortnite_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.fortnite_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.fortnite_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.fortnite_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.fortnite_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.fortnite_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.fortnite_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.fortnite_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"HyperScape"}>
                          <a href="https://www.ubisoft.com/en-us/game/hyper-scape">Hyper Scape</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.hyperscape_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Assists: ${userData.hyperscape_stats.session["assist"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Restores: ${userData.hyperscape_stats.session["restore"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.hyperscape_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.hyperscape_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Assists: ${userData.hyperscape_stats["total_assists"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Restores: ${userData.hyperscape_stats["total_restores"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.hyperscape_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"LoL"}>
                          <a href="https://play.na.leagueoflegends.com/en_US">League of Legends</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.lol_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.lol_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.lol_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.lol_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.lol_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.lol_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.lol_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.lol_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Minecraft"}>
                          <a href="https://www.minecraft.net/en-us/get-minecraft/">Minecraft</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.minecraft_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.minecraft_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.minecraft_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.minecraft_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"MinecraftDungeons"}>
                          <a href="https://www.minecraft.net/en-us/get-dungeons">Minecraft Dungeons</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Dungeons: ${userData.minecraftdungeons_stats.session["dungeon"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.minecraftdungeons_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Dungeons: ${userData.minecraftdungeons_stats["total_dungeons"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.minecraftdungeons_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"PubG"}>
                          <a href="https://www.pubg.com/en-us/">PubG</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.pubg_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.pubg_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.pubg_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.pubg_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.pubg_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.pubg_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.pubg_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.pubg_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Revn"}>
                          <a href="https://projectambitious.com/revn/">Revn</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.revn_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.revn_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.revn_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.revn_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.revn_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.revn_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.revn_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.revn_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"RingOfElysium"}>
                          <a href="https://store.steampowered.com/app/755790/Ring_of_Elysium/">Ring Of Elysium</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.ringofelysium_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.ringofelysium_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.ringofelysium_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.ringofelysium_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.ringofelysium_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.ringofelysium_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.ringofelysium_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.ringofelysium_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"DeathCounter"}>
                          Death Counter
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.deathcounter_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.deathcounter_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Valorant"}>
                          <a href="https://playvalorant.com/en-us/">Valorant</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Kills: ${userData.valorant_stats.session["kill"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.valorant_stats.session["death"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Wins: ${userData.valorant_stats.session["win"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Losts: ${userData.valorant_stats.session["lost"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Kills: ${userData.valorant_stats["total_kills"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.valorant_stats["total_deaths"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Wins: ${userData.valorant_stats["total_wins"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Losts: ${userData.valorant_stats["total_losts"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"RogueCompany"}>
                          <a href="https://www.roguecompany.com">Rogue Company</a>
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Eliminations: ${userData.roguecompany_stats.session["elim"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Downs: ${userData.roguecompany_stats.session["down"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Victories: ${userData.roguecompany_stats.session["victory"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Eliminations: ${userData.roguecompany_stats["total_elims"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Downs: ${userData.roguecompany_stats["total_downs"]}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Victories: ${userData.roguecompany_stats["total_victories"]}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"EldenRing"}>
                          Elden Ring
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.eldenring_stats.total_deaths}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.eldenring_stats.session.death}`} />
                          </ListItemButton>
                        </ListItem>
                        
                        <h2 id={"Lords Of The Fallen"}>
                          Lords Of The Fallen
                        </h2>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Deaths: ${userData.lordsofthefallen_stats.session.death}`} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem component="div" disablePadding>
                          <ListItemButton>
                            <ListItemText disableTypography={true} style={{margin: 0, fontFamily: "Roboto", fontWeight: 400, fontSize: 20, lineHeight: 0.5}} primary={`Total Deaths: ${userData.lordsofthefallen_stats.total_deaths}`} />
                          </ListItemButton>
                        </ListItem>
                      </ul>
                    </div>
                  </div>
                  ) : (
                    <div>
                      <h2>
                        {userDataError.error}
                      </h2>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Stats.propTypes = propTypes;
Stats.defaultProps = defaultProps;

export default Stats;