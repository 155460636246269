import React from "react";
import { SectionProps } from '../utils/SectionProps';
import { Form } from 'react-bootstrap';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  action: Array,
  _id: String,
  socialName: String,
  usernameBool: Boolean,
  username: String,
  socialUrlBool: Boolean,
  socialUrl: URL,
  socialUrlEnding: String,
  placeholder: String
}

const SocialContainer = (props: Props) => {
  const {
    action,
    _id,
    socialName,
    usernameBool,
    username,
    socialUrlBool,
    socialUrl,
    socialUrlEnding,
    placeholder
  } = props;

  return (
    <>
      <Form name="myform" action={action} method="post" target="hiddenFrame">
        <Form.Control type="text" name="UserId" defaultValue={_id} hidden />
        {usernameBool ? (
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding"><a href={socialUrl}>{socialName}</a> Username:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={username || placeholder}/>
			    </Form.Group>
        ) : (
          ''
        )}
        {socialUrlBool ? (
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding"><a href={socialUrl}>{socialName}</a> URL:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={socialUrlEnding || placeholder}/>
			    </Form.Group>
        ) : ('')}
      </Form>
    </>
  );
};

SocialContainer.propTypes = propTypes;
SocialContainer.defaultProps = defaultProps;

export default SocialContainer;