import React from 'react';
import { Form, Image } from 'react-bootstrap';

type Props = {
  user: Array<string>
}

const Auth0Information = (props: Props) => {
  const {
    user
  } = props;

  function togglePassword() {
    const togglePassword = document.querySelector("#togglePassword");
    const passwordV = document.querySelector("#auth_sub");
    const type = passwordV.getAttribute("type") === "password" ? "text" : "password";
    togglePassword.src === 'https://icons.getbootstrap.com/assets/icons/eye.svg' ? document.getElementById("togglePassword").src = 'https://icons.getbootstrap.com/assets/icons/eye-slash.svg' : document.getElementById("togglePassword").src = 'https://icons.getbootstrap.com/assets/icons/eye.svg';
    passwordV.setAttribute("type", type);
  }

  return (
    <>
        <h3 className="mt-0 mb-16">
          <span className="text-color-primary">Auth0 Information</span>
          <Image src={user.picture} />
        </h3>
        <div className="container-med">
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Email:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={user.email} readOnly />
          </Form.Group>
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Email Verified:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={user.email_verified} readOnly />
          </Form.Group>
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Name:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={user.name} readOnly />
          </Form.Group>
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Nickname:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={user.nickname} readOnly />
          </Form.Group>
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Sub:</Form.Label>
            <Form.Control id="auth_sub" type="password" className="input-group-addon" defaultValue={user.sub} onClick={togglePassword} readOnly />
            <Image src="https://icons.getbootstrap.com/assets/icons/eye-slash.svg" onClick={togglePassword} id="togglePassword" style={{margin: "5px"}} />
          </Form.Group>
          <Form.Group className="input-group">
            <Form.Label className="input-group-addon padding">Last Updated:</Form.Label>
            <Form.Control type="text" className="input-group-addon" defaultValue={user.updated_at} readOnly />
          </Form.Group>
        </div>
    </>
  );
}


export default Auth0Information;