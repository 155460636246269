import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Auth0Information from '../components/Auth0Information';
import AgcInformation from '../components/AgcInformation';
// import ToolTip from '../../../utils/ToolTip';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

function UserInformation (props: Props) {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
  } = props;

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <h2 className="mt-0 mb-16">
                <span className="text-color-primary">User Information</span>
              </h2>
              <Auth0Information {...props} />
              <AgcInformation {...props} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

UserInformation.propTypes = propTypes;
UserInformation.defaultProps = defaultProps;

export default UserInformation;