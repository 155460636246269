import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  returnTo: String
}

function Loading (props: Props) {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    returnTo
  } = props;

  if (returnTo === null) {
    return (
      <>
        <section
          className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
        >
          <div className="container-sm">
            <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
              <div className="hero-content">
                <div>
                  <h2>Please wait while AnyGameCounter is loading...</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  else {
    return (
      <>
        <section
          className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
        >
          <div className="container-sm">
            <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
              <div className="hero-content">
                <div>
                  <h2>Please wait while AnyGameCounter is loading...</h2>
                  <p>Loading {returnTo.replace("/", "")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;