import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import SocialContainer from '../../../components/SocialContainer';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  gamer: Array
}

const SocialInformation = (props: Props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    gamer
  } = props;

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <h2 className="mt-0 mb-16">
                <span className="text-color-primary">Social Information</span>
              </h2>
              <div className="container-med">
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Newsclapper"
                  usernameBool={true}
                  username={gamer.social_newsclapper}
                  socialUrlBool={false}
                  socialUrl="https://www.newsclapper.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.newsclapper.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Facebook"
                  usernameBool={true}
                  username={gamer.social_facebook}
                  socialUrlBool={false}
                  socialUrl="https://www.facebook.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.facebook.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Instagram"
                  usernameBool={true}
                  username={gamer.social_instagram}
                  socialUrlBool={false}
                  socialUrl="https://www.instagram.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.instagram.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="LinkedIn"
                  usernameBool={true}
                  username={gamer.social_linkedin}
                  socialUrlBool={false}
                  socialUrl="https://www.linkedin.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.linkedin.com/in/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Pinterest"
                  usernameBool={true}
                  username={gamer.social_pinterest}
                  socialUrlBool={false}
                  socialUrl="https://www.pinterest.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.pinterest.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Reddit"
                  usernameBool={true}
                  username={gamer.social_reddit}
                  socialUrlBool={false}
                  socialUrl="https://www.reddit.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.reddit.com/user/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="TikTok"
                  usernameBool={true}
                  username={gamer.social_tiktok}
                  socialUrlBool={false}
                  socialUrl="https://www.tiktok.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.tiktok.com/@AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Tumblr"
                  usernameBool={true}
                  username={gamer.social_tumbler}
                  socialUrlBool={false}
                  socialUrl="https://www.tumblr.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://AnyGameCounter.tumblr.com/'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Twitter"
                  usernameBool={true}
                  username={gamer.social_twitter}
                  socialUrlBool={false}
                  socialUrl="https://www.twitter.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://twitter.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Vimeo"
                  usernameBool={true}
                  username={gamer.social_vimeo}
                  socialUrlBool={false}
                  socialUrl="https://www.vimeo.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://vimeo.com/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Youtube"
                  usernameBool={true}
                  username={gamer.social_youtube}
                  socialUrlBool={false}
                  socialUrl="https://www.youtube.com"
                  socialUrlEnding=""
                  placeholder="Example: 'AnyGameCounter'. Not 'https://www.youtube.com/channel/AnyGameCounter'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Odysee"
                  usernameBool={true}
                  username={gamer.social_odysee}
                  socialUrlBool={false}
                  socialUrl="https://www.odysee.com"
                  socialUrlEnding=""
                  placeholder="Example: 'GG2015:b'. Not 'https://odysee.com/@GG2015:b'"
                />
                <SocialContainer
                  action="https://anygamecounter.com/api/profile/socials/update"
                  _id={gamer._id}
                  socialName="Discord"
                  usernameBool={false}
                  username={""}
                  socialUrlBool={true}
                  socialUrl="https://www.discord.com"
                  socialUrlEnding={gamer.social_discord}
                  placeholder="Example: '6vHrGEc'. Not 'discord.gg/6vHrGEc'"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

SocialInformation.propTypes = propTypes;
SocialInformation.defaultProps = defaultProps;

export default SocialInformation;