import React, { useRef, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import ScrollReveal from './utils/ScrollReveal';


// Router
import AppRouter from './AppRouter';

// Components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import SidePanel from './components/layout/SidePanel';

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/overlay") === true) {
      document.body.classList.add('is-loaded')
      childRef.current.init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (location.pathname.startsWith("/overlay") === true) {
    return (
      <>
        <AppRouter/>
      </>
    )
  }
  else {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <div>
            <Header
              navPosition="right"
            />
              {location.pathname === "/stats/search" && (
                <SidePanel />
              )}
              <main className="site-content">
                <>
                  <AppRouter/>
                </>
              </main>
            <Footer />
          </div>
        )}
      />
    );
  }
}

export default withRouter(App);