import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const FOF = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <div>
                <h2 className="mt-0 mb-16">
                  <span className="text-color-primary">404</span>
                </h2>
                <div className="container-xs">
                  <p className="m-0 mb-32">Page not found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

FOF.propTypes = propTypes;
FOF.defaultProps = defaultProps;

export default FOF;