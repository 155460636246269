import { useState, useEffect } from 'react';
import { SectionProps } from '../../../utils/SectionProps';
import { Form } from 'react-bootstrap';
import CountUp from "react-countup";
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import BackgroundImage from "./../../../assets/overlay_tint_7d2d.png";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const GoFundMe = () => {

  let location = useLocation();
  const [moneySign, setMoneySign] = useState("");
  const [moneyGoal, setMoneyGoal] = useState(0);
  const [moneyRaised, setMoneyRaised] = useState(0);
  let [moneyRaisedBoolean, setMoneyRaisedBoolean] = useState(false);
  const [position, setPosition] = useState({});

  function updateFontColor() {
    let params = new URL(document.location).searchParams;
    let theme = params.get("theme");

    if (theme) {
      if (theme === "light") {
        return "white";
      }
      if (theme === "dark") {
        return "dark";
      }
    }
  }

  useEffect(() => {
    document.body.style.background = "none";
    Array.from(document.querySelectorAll('.form-label')).forEach(
      (el) => el.classList.remove('form-label')
    );

    const socket = io("https://dev.anygamecounter.com");

    socket.on('connect', () => {
      console.log(`connected: ${socket.id}`)
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.emit("overlay", location.search.replace("?id=", ""));

    socket.on("overlay", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        setPosition({
          "left": data.gofundme_stats.page_position_left,
          "top": data.gofundme_stats.page_position_top
        })
      }
    })

    socket.on("gofundme", (data) => {
      if (data.user === location.search.replace("?id=", "")) {
        /*setPosition({
          "left": data.gofundme_stats.page_position_left,
          "top": data.gofundme_stats.page_position_top
        })*/
        if (data.GfmContent["Money Goal"] === "Goal Reached.") {
          setMoneyRaisedBoolean(true);
          setMoneySign(data.GfmContent["Money Sign"]);
          setMoneyRaised(data.GfmContent["Money Raised"]);
        }
        else {
          setMoneyRaisedBoolean(false);
          setMoneyGoal(data.GfmContent["Money Goal"]);
          setMoneySign(data.GfmContent["Money Sign"]);
          setMoneyRaised(data.GfmContent["Money Raised"]);
        }
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='overlay-background' style={{backgroundImage: `url(${BackgroundImage})`, left: position.left, top: position.top}}>
      <div style={{margin: 10}}>
        <div className="input-group">
            {moneyRaisedBoolean === false ? (
              <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>{moneySign}<CountUp start={moneyRaised} end={moneyRaised} onNewEnd={moneyRaised} duration={1} /> raised of {moneySign}<CountUp start={moneyGoal} end={moneyGoal} onNewEnd={moneyGoal} duration={1} /></Form.Label>
            ) : (
              <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Goal reached with {moneySign}<CountUp start={moneyRaised} end={moneyRaised} onNewEnd={moneyRaised} duration={1} /> raised.</Form.Label>
            )}
        </div>
      </div>
    </div>
  );
}

GoFundMe.propTypes = propTypes;
GoFundMe.defaultProps = defaultProps;

export default GoFundMe;