import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import { Form } from 'react-bootstrap';
import ToolTip from '../../../utils/ToolTip';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  gamer: Array
}

const ApiInformation = (props: Props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    gamer
  } = props;

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <h2 className="mt-0 mb-16">
                <span className="text-color-primary">API Information</span>
              </h2>
              <div className="container-med">
                <Form>
                  <Form.Control name="UserId" defaultValue={gamer._id} hidden />
                  <Form.Label className="input-group-addon">Apex</Form.Label>
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Platform:</Form.Label>
                    <select className="input-group-addon" name="ApexGamePlatform">
                      <option>{gamer.apex_api_game_platform || 'Select from the following'}</option>
                      <option value="DISABLED">DISABLED</option>
                      <option value="XBOX">XBOX</option>
                      <option value="PSN">PSN</option>
                      <option value="ORIGIN/PC">ORIGIN/PC</option>
                    </select>
                    <Form.Label id="showhelp-apex-platform" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-apex-platform" className="help-apex-platform tooltip">
                    <Form.Label className="input-group-addon">The platform that you play Apex on.</Form.Label>
						      </div>
                
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Username:</Form.Label>
                    <Form.Control className="input-group-addon" name="ApexUsername" defaultValue={gamer.apex_username} placeholder="Apex Username" />
							      <Form.Label id="showhelp-apex-username" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-apex-username" className="help-apex-username tooltip">
                    <Form.Label className="input-group-addon">Your Apex Username</Form.Label>
						      </div>
                  <div className="input-group">
                    <Form.Label className="input-group-addon">0 = Disabled. 1 = Xbox. 2 = PSN. 5 = Origin / PC.</Form.Label>
                  </div>
                  
                  <Form.Label className="input-group-addon">Call of Duty: Modern Warfare & Warzone</Form.Label>
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Platform:</Form.Label>
							      <select className="input-group-addon" name="CodmwGamePlatform">
                      <option>{gamer.codmw_api_game_platform || 'Select from the following'}</option>
                      <option value="psn">PSN</option>
                      <option value="steam">STEAM</option>
                      <option value="xbl">XBOX</option>
                      <option value="battle">BATTLE.NET</option>
                      <option value="uno">UNO/ACTIVISION</option>
                    </select>
							      <Form.Label id="showhelp-cod-platform" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-cod-platform" className="help-cod-platform tooltip">
                    <Form.Label className="input-group-addon">The platform you play CoD on.</Form.Label>
						      </div>
						      
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Username:</Form.Label>
                    <Form.Control className="input-group-addon" name="CodmwUsername" defaultValue={gamer.codmw_username} placeholder="CoD MW Username" />
                    <Form.Label id="showhelp-cod-username" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-cod-username" className="help-cod-username tooltip">
                    <Form.Label className="input-group-addon">Your CoD Username. If playing on Battle.net, include the ending numbers.</Form.Label>
						      </div>
                  
                  <Form.Label className="input-group-addon">Destiny 2</Form.Label>
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Platform:</Form.Label>
                    <select className="input-group-addon" name="Destiny2GamePlatform">
                      <option>{gamer.destiny2_api_game_platform || 'Select from the following'}</option>
								      <option value="DISABLED">DISABLED</option>
                      <option value="XBOX">XBOX</option>
                      <option value="PSN">PSN</option>
                      <option value="STEAM">STEAM</option>
                      <option value="BLIZZARD">BLIZZARD</option>
                      <option value="STADIA">STADIA</option>
                      <option value="DEMON">DEMON</option>
                      <option value="BUNGIENEXT">BUNGIENEXT</option>
                    </select>
							      <Form.Label id="showhelp-destiny2-platform" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-destiny2-platform" className="help-destiny2-platform tooltip">
                    <Form.Label className="input-group-addon">The platform you play Destiny 2 on.</Form.Label>
						      </div>
						      
                  <div className="input-group">
                    <Form.Label className="input-group-addon">Account ID:</Form.Label>
                    <Form.Control type="number" className="input-group-addon" name="Destiny2AccountId" defaultValue="{gamer.destiny2_account_id}" placeholder="Numbers only." />
							      <Form.Label id="showhelp-destiny2-account-id" className="input-group-addon" onClick={ToolTip}>?</Form.Label>
                  </div>
						      <div id="help-destiny2-account-id" className="help-destiny2-account-id tooltip">
                    <Form.Label className="input-group-addon">I recommend going to <a href="https://destinytracker.com/">Destiny Tracker</a> to get your Account ID. The numbers in this URL which you can get from finding your profile via Destiny Tracker, is your Account ID. https://destinytracker.com/destiny-2/profile/steam/4611686018506535955/overview</Form.Label>
						      </div>
                  <div className="input-group">
                    <Form.Label className="input-group-addon">0 = Disabled. 1 = Xbox. 2 = PSN. 3 = Steam. 4 = Blizzard. 5 = Stadia. 10 = Demon. 254 = BungieNext.</Form.Label>
                  </div>
                  <div id="submitbutton">
                    <Form.Control className="input-group-addon" type="submit" name="button" defaultValue="Update Settings" />
						      </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ApiInformation.propTypes = propTypes;
ApiInformation.defaultProps = defaultProps;

export default ApiInformation;