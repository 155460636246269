import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

//import App from "./App";
import AuthProvider from "./components/Auth0/Auth0_Provider";
import * as serviceWorker from "./serviceWorker";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import "./assets/scss/style.scss";

Bugsnag.start({
  apiKey: 'e773e46e7c8a8b70c4dcaafa0503a56d',
  plugins: [new BugsnagPluginReact()],
  onError: function (event) {
    event.addMetadata('company', {
      agent: window.navigator.userAgent,
      url: window.location.href
    })
  }
})
BugsnagPerformance.start({
  apiKey: 'e773e46e7c8a8b70c4dcaafa0503a56d',
  releaseStage: 'Development'
})

const history = createBrowserHistory();
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
function ErrorView () {
  return (
    <div>
      <p>An error occurred and has been logged.</p>
    </div>
  )
}

function AppWrapper() {
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Router history={history}>
        <Fragment>
          <AuthProvider />
        </Fragment>
      </Router>
    </ErrorBoundary>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById("app"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
