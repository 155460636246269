import React, { useState, useEffect } from 'react';
import { SectionProps } from '../../utils/SectionProps';
import { useAuth0 } from "@auth0/auth0-react";
import UserInformation from './modules/UserInformation';
import ApiInformation from './modules/ApiInformation';
import OverlayPositions from './modules/OverlayPositions';
import SocialInformation from './modules/SocialInformation';


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

/*const Profile = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {*/
function Profile () {

  const { user, isLoading } = useAuth0();
  const [gamer, setGamer] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`https://dev.anygamecounter.com/api/getUserStatsEmail/${user.email}`);
    const json = await response.json();
    return setGamer(json)
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGamer]);

  if (isLoading) {
    return <div>Loading ...</div>
  }

  return (
    <>
      <div>
        <UserInformation gamer={gamer} user={user} />
        <SocialInformation gamer={gamer} />
        <ApiInformation gamer={gamer} />
        <OverlayPositions gamer={gamer} />
      </div>
    </>
  );
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;