import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Stats = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  const [users, setUsers] = useState();

  useEffect(() => {
    fetch(`https://dev.anygamecounter.com/api/getUsers`).then((response) => response.json()).then(data => {
      if (data.length > 0) {
        setUsers(data);
      }
    })
  }, [])

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <div>
                {
                  users && users.length && (
                    Object.keys(users).map((e, i) => {
                      return  <div key={i}><h2 className="mt-0 mb-16"><span className="text-color-primary"><Link to={`./stats/search?${users[e].user}`}>{users[e].user.toString().toUpperCase()}</Link></span></h2></div>
                    })
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Stats.propTypes = propTypes;
Stats.defaultProps = defaultProps;

export default Stats;