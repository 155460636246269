import Modal from '../Modal';
import Image from '../Image';
import { useState } from 'react';

function Testimonial_Center ({
  reviewer,
  reviewerAppUrl,
  reviewerApp,
  reviewerText,
  reviewerMovie,
  reviewerImage,
  reviewerImageAlt,
}) {
  
    const [videoModalActive, setVideomodalactive] = useState(false);
  
    const openModal = (e) => {
      e.preventDefault();
      setVideomodalactive(true);
    }
  
    const closeModal = (e) => {
      e.preventDefault();
      setVideomodalactive(false);
    }
  
    return (
      <>
        <div className="tiles-item reveal-from-bottom">
          <div className="tiles-item-inner">
            {reviewerText && (
              <div className="testimonial-item-content">
                <p className="text-sm mb-0">
                  {reviewerText}
                </p>
              </div>
            )}
            {reviewerMovie && (
              <div className="hero-figure reveal-from-bottom illustration-element-01">
                <a
                  data-video={reviewerMovie}
                  href="#0"
                  aria-controls="video-modal"
                  onClick={openModal}
                >
                  <Image
                    className="has-shadow"
                    src={reviewerImage}
                    alt={reviewerImageAlt}
                    width={896}
                    height={504} />
                  <Modal
                    id="video-modal"
                    show={videoModalActive}
                    handleClose={closeModal}
                    video={reviewerMovie}
                    videoTag="iframe" />
                </a>
              </div>
            )}
            <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
              <span className="testimonial-item-name text-color-high">{reviewer}</span>
              <span className="text-color-low"> / </span>
              <span className="testimonial-item-link">
                <a href={reviewerAppUrl}>{reviewerApp}</a>
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }

export default Testimonial_Center;