import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';
import Image from '../components/elements/Image';
import ThreeCounters from '../components/elements/Counter';
//import TestimonialLeft from '../components/elements/Testimonial/Testimonial_Left';
import TestimonialCenter from '../components/elements/Testimonial/Testimonial_Center';
//import TestimonialRight from '../components/elements/Testimonial/Testimonial_Right';

import SectionHeader from '../components/elements/SectionHeader';


// Testimonials
import reviewerImage1 from "./../assets/images/PreFirezTV-EFT-AGC.png";
import reviewerVideo1 from "./../assets/videos/PreFirezTV-EFT-AGC.mp4";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Home = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  /* Section 1 */
  const section1_outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const section1_innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [userCount, setUserCount] = useState(0);
  const [gameApiCount, setGameApiCount] = useState(0);
  const [nonGameApiCount, setNonGameApiCount] = useState(0);

  fetch(`https://dev.anygamecounter.com/api/getUserCount`).then(data => data.json()).then(data2 => {
    setUserCount(data2.data);
  })
  fetch(`https://dev.anygamecounter.com/api/getOverlayApiCount`).then(data => data.json()).then(data2 => {
    setGameApiCount(data2.data);
  })
  fetch(`https://dev.anygamecounter.com/api/getOverlayCount`).then(data => data.json()).then(data2 => {
    setNonGameApiCount(data2.data);
  })

  /* Section 2 */

  const section2_outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const section2_innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const section2_tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const section2_header = {
    title: '',
    paragraph: ''
  };

  return (
    <>
      <section
        className={section1_outerClasses}
      >
        <div className="container-sm">
          <div className={section1_innerClasses}>
            <div className="hero-content">
              <h1 className="mt-0 mb-16">
                <span className="text-color-primary">{process.env.REACT_APP_SHORTNAME}</span>
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32" style={{color: 'red'}}>
                  This version of AGC may change, all features seen or available at the time may change without notice.
                </p>
                <p className="m-0 mb-32">
                  Is an SaaS currently in Closed Beta testing, you can track your favorite game stats with API or by commands that are saved to our Database and can be accessed now.
                  <br />Currently being maintained by one person.
                </p>

                <ThreeCounters
                  // Counter 1
                  count1={userCount} header1={"Users"} paragraph1={("On this platform.")}
                  // Counter 2
                  count2={gameApiCount} header2={"Overlays with API"} paragraph2
                  // Counter 3
                  count3={nonGameApiCount} header3={"Overlays"} paragraph3
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={section2_outerClasses}
      >
        <div className="container">
          <div className={section2_innerClasses}>
            <SectionHeader data={section2_header} className="center-content" />
            <div className={section2_tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="mb-16">
                      <Image
                        src={require('./../assets/images/questionMark.png')}
                        alt="Question mark"
                        width={128}
                        height={128} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Requirements?
                    </h4>
                    <p className="m-0 text-sm">
                      Streaming software that allows you to use Browser Sources.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="mb-16">
                      <Image
                        src={require('./../assets/images/questionMark.png')}
                        alt="Question mark"
                        width={128}
                        height={128} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      How to get access?
                    </h4>
                    <p className="m-0 text-sm">
                      Unforunately as of this moment, it is invite only. Once AnyGameCounter goes public, AnyGameCounter will be a free service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={classNames('testimonial-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}
      >
        <div className="container">
          <div className={classNames('testimonial-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <SectionHeader data={{
              title: 'Customer testimonials',
              paragraph: ''
            }} className="center-content" />
            <div className={classNames('tiles-wrap', pushLeft && 'push-left')}>
              {/*<TestimonialLeft
                reviewer = "Name"
                reviewerAppUrl = "#"
                reviewerApp = "Twitch"
                reviewerText = "This App is great."
                reviewerMovie = {reviewerVideo1}
                reviewerImage = {reviewerImage1}
                reviewerImageAlt
              />*/}
              <TestimonialCenter
                reviewer = "PreFirezTV"
                reviewerAppUrl = "https://twitch.tv/PreFirezTV"
                reviewerApp = "Twitch"
                reviewerText = "PreFirezTV playing EFT with Wolfar15 while using AGC."
                reviewerMovie = {reviewerVideo1}
                reviewerImage = {reviewerImage1}
                reviewerImageAlt = "PreFirezTV playing EFT with Wolfar15 while using AGC."
              />
              {/*<TestimonialRight
                reviewer = "Name"
                reviewerAppUrl = "#"
                reviewerApp = "Odysee"
                reviewerText = "This App is great."
                reviewerMovie = {reviewerVideo1}
                reviewerImage = {reviewerImage1}
                reviewerImageAlt
              />*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;