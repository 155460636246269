import React from "react";
import { SectionProps } from './../../utils/SectionProps';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Auth0_LogoutButton = () => {

  const { logout } = useAuth0();

  return <Link to="" className="login" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } }) }>Log Out</Link>;
};

Auth0_LogoutButton.propTypes = propTypes;
Auth0_LogoutButton.defaultProps = defaultProps;

export default Auth0_LogoutButton;