import React from "react";
import { SectionProps } from './../../utils/SectionProps';
import App from "../../App";
import { Auth0Provider } from "@auth0/auth0-react";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Auth0_Provider = () => {

return (
  <>
    <Auth0Provider
      domain={"dev-anygamecounter.us.auth0.com"}
      clientId={"vthpj5bxvH5abrGXsyNYHppq8Y00mSOw"}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      >
      <App />
    </Auth0Provider>
  </>
)}

Auth0_Provider.propTypes = propTypes;
Auth0_Provider.defaultProps = defaultProps;

export default Auth0_Provider;