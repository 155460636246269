import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Api = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <div>
                <p>
                  AnyGameCounter is using the following API's to get stats.
                </p>
                <h2 className="mt-0 mb-16">
                  <span className="text-color-primary">Unofficial API's</span>
                </h2>
                <p>Apex</p>
                <h2 className="mt-0 mb-16">
                  <span className="text-color-primary">Official API's</span>
                </h2>
                <p>
                  Call of Duty: Modern Warfare<br />
                  Destiny 2
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Api.propTypes = propTypes;
Api.defaultProps = defaultProps;

export default Api;