import React, { useState, useEffect, Suspense } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import OverlayPositionContainer from '../../../components/OverlayPositionContainer';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  gamer: Array
}

function OverlayPositions (props: Props) {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    gamer
  } = props;

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <div className="container-sm">
          <div className={classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider')}>
            <div className="hero-content">
              <h2 className="mt-0 mb-16">
                <span className="text-color-primary">Overlay Positions</span>
              </h2>
              <div className="container-med">
                {props.gamer._id ? (
                  <>
                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/SevenDaysToDieOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/251570/header.jpg?t=1599069217"
                      _id={gamer._id}
                      positionLeft={gamer.sevend2d_stats.page_position_left}
                      positionLeftPlaceholder="1785"
                      positionTop={gamer.sevend2d_stats.page_position_top}
                      positionTopPlaceholder="50"
                      positionWidth={gamer.sevend2d_stats.page_position_width}
                      positionWidthPlaceholder="190"
                      buttonValue="Update 7 Days 2 Die Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/ApexOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/1172470/header.jpg?t=1600443439"
                      _id={gamer._id}
                      positionLeft={gamer.apex_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.apex_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.apex_stats.page_position_width}
                      positionWidthPlaceholder="240"
                      buttonValue="Update Apex Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/ArkSurvivalEvolvedOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/346110/header.jpg?t=1595349770"
                      _id={gamer._id}
                      positionLeft={gamer.ark_page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.ark_page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.ark_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Ark Survival Evolved Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/CallOfDutyModernWarfareOverlayPosition"
                      imageSrc="https://store.playstation.com/store/api/chihiro/00_09_000/container/US/en/19/UP0002-CUSA08829_00-CODMWTHEGAME0001/1601174374000/image?w=240&h=240&bg_color=000000&opacity=100&_version=00_09_000"
                      _id={gamer._id}
                      positionLeft={gamer.codmw_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.codmw_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.codmw_stats.page_position_width}
                      positionWidthPlaceholder="190"
                      buttonValue="Update Call Of Duty: Modern Warfare Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/CallOfDutyWarzoneOverlayPosition"
                      imageSrc="https://imgs.callofduty.com/content/dam/atvi/callofduty/cod-touchui/warzone2/mw2/common/logos/warzone-2-logo-en.png"
                      _id={gamer._id}
                      positionLeft={gamer.codwz_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.codwz_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.codwz_stats.page_position_width}
                      positionWidthPlaceholder="240"
                      buttonValue="Update Call Of Duty: Modern Warfare Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/CSGOOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/730/header.jpg?t=1592263625"
                      _id={gamer._id}
                      positionLeft={gamer.csgo_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.csgo_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.csgo_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update CS:GO Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/DeathCounterOverlayPosition"
                      imageSrc=""
                      _id={gamer._id}
                      positionLeft={gamer.deathcounter_stats.page_position_left}
                      positionLeftPlaceholder="1785"
                      positionTop={gamer.deathcounter_stats.page_position_top}
                      positionTopPlaceholder="50"
                      positionWidth={gamer.deathcounter_stats.page_position_width}
                      positionWidthPlaceholder="140"
                      buttonValue="Update Death Counter Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/DestinyTwoPveOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/1085660/header.jpg?t=1598982557"
                      _id={gamer._id}
                      positionLeft={gamer.destiny2_stats.destiny2pve_page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.destiny2_stats.destiny2pve_page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.destiny2_stats.destiny2pve_page_position_width}
                      positionWidthPlaceholder="420"
                      buttonValue="Update Destiny 2 PvE Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/DestinyTwoPvpOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/1085660/header.jpg?t=1598982557"
                      _id={gamer._id}
                      positionLeft={gamer.destiny2_stats.destiny2pvp_page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.destiny2_stats.destiny2pvp_page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.destiny2_stats.destiny2pvp_page_position_width}
                      positionWidthPlaceholder="180"
                      buttonValue="Update Destiny 2 PvP Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/EscapeFromTarkovOverlayPosition"
                      imageSrc="https://www.escapefromtarkov.com/themes/eft/images/logo.png"
                      _id={gamer._id}
                      positionLeft={gamer.tarkov_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.tarkov_stats.page_position_top}
                      positionTopPlaceholder="60"
                      positionWidth={gamer.tarkov_stats.page_position_width}
                      positionWidthPlaceholder="210"
                      buttonValue="Update Escape From Tarkov Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/FallGuysOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/1097150/header.jpg?t=1598911632"
                      _id={gamer._id}
                      positionLeft={gamer.fallguys_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.fallguys_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.fallguys_stats.page_position_width}
                      positionWidthPlaceholder="145"
                      buttonValue="Update Fall Guys Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/FortniteOverlayPosition"
                      imageSrc="https://cdn2.unrealengine.com/chapter4-logo-no-fortnite-en-1252x529-18931e544f9d.png"
                      _id={gamer._id}
                      positionLeft={gamer.fortnite_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.fortnite_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.fortnite_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Fortnite Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/HyperScapeOverlayPosition"
                      imageSrc="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTLGXO0heWU8KivDLyhznmSpBS_mZwy2QMh2abC0a--bf9a7HNG"
                      _id={gamer._id}
                      positionLeft={gamer.hyperscape_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.hyperscape_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.hyperscape_stats.page_position_width}
                      positionWidthPlaceholder="190"
                      buttonValue="Update Hyper Scape Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/LeagueOfLegendsOverlayPosition"
                      imageSrc="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcStJOZJ8I6GWv1kqOJVgJL7EsLYfLmiL-Vxbu7BpPrurPsUHXvE"
                      _id={gamer._id}
                      positionLeft={gamer.lol_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.lol_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.lol_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update League Of Legends Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/MinecraftOverlayPosition"
                      imageSrc="https://lh3.googleusercontent.com/yAtZnNL-9Eb5VYSsCaOC7KAsOVIJcY8mpKa0MoF-0HCL6b0OrFcBizURHywpuip-D6Y"
                      _id={gamer._id}
                      positionLeft={gamer.minecraft_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.minecraft_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.minecraft_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Minecraft Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/MinecraftDungeonsOverlayPosition"
                      imageSrc="https://www.minecraft.net/content/dam/minecraft/news/dungeons-news-/header.jpeg.transform/minecraft-image-large/image.jpeg"
                      _id={gamer._id}
                      positionLeft={gamer.minecraftdungeons_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.minecraftdungeons_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.minecraftdungeons_stats.page_position_width}
                      positionWidthPlaceholder="200"
                      buttonValue="Update Minecraft Dungeons Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/PubGOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/578080/header.jpg?t=1596674062"
                      _id={gamer._id}
                      positionLeft={gamer.pubg_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.pubg_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.pubg_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update PubG Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/RevnOverlayPosition"
                      imageSrc="https://revngame.com/wp-content/uploads/2020/06/main_banner-1536x912.jpg"
                      _id={gamer._id}
                      positionLeft={gamer.revn_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.revn_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.revn_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Revn Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/RingOfElysiumOverlayPosition"
                      imageSrc="https://steamcdn-a.akamaihd.net/steam/apps/755790/header.jpg?t=1599112942"
                      _id={gamer._id}
                      positionLeft={gamer.ringofelysium_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.ringofelysium_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.ringofelysium_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Ring Of Elysium Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/RogueCompanyOverlayPosition"
                      imageSrc="https://cdn2.unrealengine.com/renditiondownload-400x400-2f80641cdc5b.png?h=270&resize=1&w=480"
                      _id={gamer._id}
                      positionLeft={gamer.roguecompany_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.roguecompany_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.roguecompany_stats.page_position_width}
                      positionWidthPlaceholder="230"
                      buttonValue="Update Rogue Company Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/ValorantOverlayPosition"
                      imageSrc=""
                      _id={gamer._id}
                      positionLeft={gamer.valorant_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.valorant_stats.page_position_top}
                      positionTopPlaceholder="0"
                      positionWidth={gamer.valorant_stats.page_position_width}
                      positionWidthPlaceholder="170"
                      buttonValue="Update Valorant Overlay Position"
                    />

                    <OverlayPositionContainer
                      formAction="http://localhost:3001/api/profile/GofundmeOverlayPosition"
                      imageSrc=""
                      _id={gamer._id}
                      positionLeft={gamer.gofundme_stats.page_position_left}
                      positionLeftPlaceholder="0"
                      positionTop={gamer.gofundme_stats.page_position_top}
                      positionTopPlaceholder="0"
                      // positionWidth={gamer._stats.page_position_width}
                      // positionWidthPlaceholder="170"
                      gofundmeUrl={gamer.gofundme_url || "/f/gqyths-help-mike-fight-colon-cancer"}
                      gofundmeUrlPlaceholder="/f/gqyths-help-mike-fight-colon-cancer"
                      buttonValue="Update Gofundme Overlay"
                    />
                  </>
                ) : ('')
              }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

OverlayPositions.propTypes = propTypes;
OverlayPositions.defaultProps = defaultProps;

export default OverlayPositions;