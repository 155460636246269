import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

function ResponsiveDrawer(props) {

  let location = useLocation();

  return (
    <Box sx={{ display: 'flex'}}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#151719', borderRight: 1, borderRightColor: 'white' },
          }}
          open
        >
          <div style={{backgroundColor: '#151719'}}>
            <Toolbar />
            {location.pathname === "/stats/search" && (
              <List sx={{backgroundColor: '#151719' }} >
                <ListItem disablePadding>
                  <ListItemButton href={"#top"}>
                    <ListItemText sx={{color: '#fff'}} primary={"Back To Top"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#7Days2Die"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"7 Days 2 Die"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#ApexLegends"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Apex Legends"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#ArkSurvivalEvolved"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Ark: Survival Evolved"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#CODMW"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"CoD: MW"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#CODWZ"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"CoD: WZ"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#CSGO"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"CS:GO"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#Destiny2"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Destiny 2"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#EscapeFromTarkov"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Escape From Tarkov"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#EldenRing"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Elden Ring"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#FallGuys"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Fall Guys"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#Fortnite"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Fortnite"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#HyperScape"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Hyper Scape"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#LoL"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"LoL"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#LordsOfTheFallen"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Lords Of The Fallen"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#Minecraft"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Minecraft"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#MinecraftDungeons"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Minecraft Dungeons"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#PubG"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"PubG"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#Revn"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Revn"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#RingOfElysium"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Ring Of Elysium"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#DeathCounter"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Death Counter"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#Valorant"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Valorant"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#RogueCompany"}>
                    <ListItemText sx={{color: '#9CA9B3'}} primary={"Rogue Company"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href={"#top"}>
                    <ListItemText sx={{color: '#fff'}} primary={"Back To Top"} />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </div>
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;