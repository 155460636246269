import { useState, useEffect } from 'react';
import { SectionProps } from '../../../utils/SectionProps';
import { Form } from 'react-bootstrap';
import CountUp from "react-countup";
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import BackgroundImage from "./../../../assets/overlay_tint_7d2d.png";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const RingOfElysium = () => {

  let location = useLocation();
  const [kill, setKill] = useState(0);
  const [death, setDeath] = useState(0);
  const [win, setWin] = useState(0);
  const [lost, setLost] = useState(0);
  const [position, setPosition] = useState({});

  function updateFontColor() {
    let params = new URL(document.location).searchParams;
    let theme = params.get("theme");

    if (theme) {
      if (theme === "light") {
        return "white";
      }
      if (theme === "dark") {
        return "dark";
      }
    }
  }

  useEffect(() => {
    document.body.style.background = "none";
    Array.from(document.querySelectorAll('.form-label')).forEach(
      (el) => el.classList.remove('form-label')
    );

    const socket = io("https://dev.anygamecounter.com");

    socket.on('connect', () => {
      console.log(`connected: ${socket.id}`)
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.emit("overlay", location.search.replace("?id=", ""));

    socket.on("overlay", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        setPosition({
          "left": data.ringofelysium_stats.page_position_left,
          "top": data.ringofelysium_stats.page_position_top,
          "width": data.ringofelysium_stats.page_position_width
        })
        setKill(data.ringofelysium_stats.session.kill);
        setDeath(data.ringofelysium_stats.session.death);
        setWin(data.ringofelysium_stats.session.win);
        setLost(data.ringofelysium_stats.session.lost);
      }
    })

    socket.on("ringofelysium", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        /*setPosition({
          "left": data.ringofelysium_stats.page_position_left,
          "top": data.ringofelysium_stats.page_position_top,
          "width": data.ringofelysium_stats.page_position_width
        })*/
        setKill(data.ringofelysium_stats.session.kill);
        setDeath(data.ringofelysium_stats.session.death);
        setWin(data.ringofelysium_stats.session.win);
        setLost(data.ringofelysium_stats.session.lost);
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='overlay-background' style={{ backgroundImage: `url(${BackgroundImage})`, left: position.left, top: position.top, width: position.width }}>
      <div style={{margin: 10}}>
        <div className="input-group">
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Kills: <CountUp start={kill} end={kill} onNewEnd={kill} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Deaths: <CountUp start={death} end={death} onNewEnd={death} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Wins: <CountUp start={win} end={win} onNewEnd={win} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Losses: <CountUp start={lost} end={lost} onNewEnd={lost} duration={1} /></Form.Label>
        </div>
      </div>
    </div>
  );
}

RingOfElysium.propTypes = propTypes;
RingOfElysium.defaultProps = defaultProps;

export default RingOfElysium;