import React, { useState, useEffect } from 'react';
import { SectionProps } from './../../utils/SectionProps';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const BrowserSource = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  const [user, setUser] = useState({});
  let location = useLocation();
  let params = new URL(document.location).searchParams;
  let id = params.get("id");
  let theme = params.get("theme");

  useEffect(() => {
    document.body.style.background = "none";

    const socket = io("https://dev.anygamecounter.com");

    socket.on('connect', () => {
      console.log(`connected: ${socket.id}`)
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.on('error', (err) => {
      console.log(err)
    })

    socket.emit("overlay", id);

    socket.on("overlay", (data) => {
      if (data._id === id) {
        setUser(data);
        updateGame(data.game, data._id);
      }
    })

    socket.on("BrowserSource", (data) => {
      if (data.user._id === id) {
        updateGame(data.user.game, data.user._id);
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateGame(game, id) {
    console.log(game)
    if (game === null || "null" || undefined || "undefined" || "disabled") {
      document.getElementsByName("iFrame")[0].src = `/overlay/disabled?id=${id}&theme=${theme}`;
    }
    if (game === "apexlegends") {
      document.getElementsByName("iFrame")[0].src = `/overlay/apexlegends?id=${id}&theme=${theme}`;
    }
    if (game === "arksurvivalevolved") {
      document.getElementsByName("iFrame")[0].src = `/overlay/arksurvivalevolved?id=${id}&theme=${theme}`;
    }
    if (game === "callofdutymodernwarfare") {
      document.getElementsByName("iFrame")[0].src = `/overlay/callofdutymodernwarfare?id=${id}&theme=${theme}`;
    }
    if (game === "callofdutywarzone") {
      document.getElementsByName("iFrame")[0].src = `/overlay/callofdutywarzone?id=${id}&theme=${theme}`;
    }
    if (game === "csgo") {
      document.getElementsByName("iFrame")[0].src = `/overlay/csgo?id=${id}&theme=${theme}`;
    }
    if (game === "deathcounter") {
      document.getElementsByName("iFrame")[0].src = `/overlay/deathcounter?id=${id}&theme=${theme}`;
    }
    if (game === "destiny2pve") {
      document.getElementsByName("iFrame")[0].src = `/overlay/destiny2pve?id=${id}&theme=${theme}`;
    }
    if (game === "destiny2pvp") {
      document.getElementsByName("iFrame")[0].src = `/overlay/destiny2pvp?id=${id}&theme=${theme}`;
    }
    if (game === "escapefromtarkov") {
      document.getElementsByName("iFrame")[0].src = `/overlay/escapefromtarkov?id=${id}&theme=${theme}`;
    }
    if (game === "eldenring") {
      document.getElementsByName("iFrame")[0].src = `/overlay/eldenring?id=${id}&theme=${theme}`;
    }
    if (game === "fallguys") {
      document.getElementsByName("iFrame")[0].src = `/overlay/fallguys?id=${id}&theme=${theme}`;
    }
    if (game === "fortnite") {
      document.getElementsByName("iFrame")[0].src = `/overlay/fortnite?id=${id}&theme=${theme}`;
    }
    if (game === "gofundme") {
      document.getElementsByName("iFrame")[0].src = `/overlay/gofundme?id=${id}&theme=${theme}`;
    }
    if (game === "hyperscape") {
      document.getElementsByName("iFrame")[0].src = `/overlay/hyperscape?id=${id}&theme=${theme}`;
    }
    if (game === "leagueoflegends") {
      document.getElementsByName("iFrame")[0].src = `/overlay/leagueoflegends?id=${id}&theme=${theme}`;
    }
    if (game === "lordsofthefallen") {
      document.getElementsByName("iFrame")[0].src = `/overlay/lordsofthefallen?id=${id}&theme=${theme}`;
    }
    if (game === "minecraft") {
      document.getElementsByName("iFrame")[0].src = `/overlay/minecraft?id=${id}&theme=${theme}`;
    }
    if (game === "minecraftdungeons") {
      document.getElementsByName("iFrame")[0].src = `/overlay/minecraftdungeons?id=${id}&theme=${theme}`;
    }
    if (game === "pubg") {
      document.getElementsByName("iFrame")[0].src = `/overlay/pubg?id=${id}&theme=${theme}`;
    }
    if (game === "revn") {
      document.getElementsByName("iFrame")[0].src = `/overlay/revn?id=${id}&theme=${theme}`;
    }
    if (game === "ringofelysium") {
      document.getElementsByName("iFrame")[0].src = `/overlay/ringofelysium?id=${id}&theme=${theme}`;
    }
    if (game === "roguecompany") {
      document.getElementsByName("iFrame")[0].src = `/overlay/roguecompany?id=${id}&theme=${theme}`;
    }
    if (game === "sevendaystodie") {
      document.getElementsByName("iFrame")[0].src = `/overlay/sevendaystodie?id=${id}&theme=${theme}`;
    }
    if (game === "valorant") {
      document.getElementsByName("iFrame")[0].src = `/overlay/valorant?id=${id}&theme=${theme}`;
    }
  }

  return (
    <>
      <div style={{background: "transparent !important"}}>
        <iframe title="Main Overlay" src ={`/overlay/${user.game}?id=${location.search.replace("?id=", "")}`} name="iFrame" style={{position: "fixed", left:0, top:0, right:0, bottom:0, width: "100%", height: "100%", border: 1, backgroundColor: "transparent" }}></iframe>
      </div>
    </>
  );
}

BrowserSource.propTypes = propTypes;
BrowserSource.defaultProps = defaultProps;

export default BrowserSource;