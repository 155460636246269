import React from "react";
import { SectionProps } from './../../utils/SectionProps';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Auth0_LoginButton = () => {

  const { loginWithRedirect } = useAuth0();

  return <Link to="" className="login" onClick={() => loginWithRedirect({ appState: { returnTo: "/profile" } })}>Log In</Link>;
};

Auth0_LoginButton.propTypes = propTypes;
Auth0_LoginButton.defaultProps = defaultProps;

export default Auth0_LoginButton;