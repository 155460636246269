import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

type Props = {
  gamer: Array<string>
}

const AgcInformation = (props: Props) => {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    hasBgColor,
    invertColor,
    gamer
  } = props;

  return (
    <>
      <section
        className={classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className)}
      >
        <h3 className="mt-0 mb-16" style={{paddingTop: "5px"}}>
          <span className="text-color-primary">AGC Information</span>
        </h3>
        <div className="container-med">
          <Form>
					  <Form.Group controlId="test" className="input-group">
              <Form.Label className="input-group-addon padding">Account Type:</Form.Label>
              <Form.Control type="text" className="input-group-addon" defaultValue={gamer.membership_type} readOnly />
					  </Form.Group>
            <Form.Group className="input-group">
              <Form.Label className="input-group-addon padding">Username:</Form.Label>
              <Form.Control type="text" className="input-group-addon" defaultValue={gamer.username} readOnly />
            </Form.Group>

            <Form.Control type="text" name="UserId" defaultValue={gamer._id} hidden />

            <Form.Group className="input-group">
              <Form.Label className="input-group-addon padding">Browser Source URL:</Form.Label>
              <Form.Label className="input-group-addon">
                <Link to={`/overlay/browsersource?id=${gamer._id}`}>Right click to copy URL.</Link>
              </Form.Label>
              <Form.Label id="showhelp-browser-source" className="input-group-addon" /*onClick={ToolTip}*/>?</Form.Label>
            </Form.Group>
					
            <div id="help-browser-source" className="help-browser-source tooltip">
              <Form.Label className="input-group-addon">This is the URL you will use for your overlays. Do not share it, you are not able to get a new one.</Form.Label>
					  </div>

            <Form.Group className="input-group">
              <Form.Label className="input-group-addon alert">Warning! If you change your Email Address, you can possibly lose access if not careful.</Form.Label>
            </Form.Group>
                  
            <Form.Group className="input-group">
              <Form.Label className="input-group-addon padding">Email Address:</Form.Label>
              <Form.Control type="text" className="input-group-addon" defaultValue={gamer.email_address} placeholder="Your Email Address" />
            </Form.Group>

            <Form.Group className="input-group">
              <Form.Label className="input-group-addon alert">Warning! If you change your Email Address, you can possibly lose access if not careful.</Form.Label>
            </Form.Group>

            <Form.Group className="input-group">
              <Form.Label className="input-group-addon padding">Game:</Form.Label>
              <Form.Control type="text" className="input-group-addon" name="Game" defaultValue={gamer.game} readOnly />
						  <Form.Label id="showhelp-game" className="input-group-addon" /*onClick={ToolTip}*/>?</Form.Label>
            </Form.Group>
					
            <div id="help-game" className="help-game tooltip">
              <Form.Label className="input-group-addon">Your current Overlay Game.</Form.Label>
					  </div>
            			
            <div id="submitbutton">
              <Button className="input-group-addon" type="submit" name="button">Update User Information</Button>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
}

AgcInformation.propTypes = propTypes;
AgcInformation.defaultProps = defaultProps;

export default AgcInformation;