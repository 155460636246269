import CountUp from "react-countup";

function ThreeCounters ({
  // Counter 1
  count1, header1, paragraph1,

  // Counter 2
  count2, header2, paragraph2,

  // Counter 3
  count3, header3, paragraph3,
}) {
  return (
    <div className='counter'>
      <div className="counter-row">
        <div className="counter-div-1">
          <div className="counter-div-2">
            <h1 className="counter-h1">
              {<CountUp end={count1} duration={1} />}
            </h1>
            <h5 className="counter-h5">{header1}</h5>
            <p className="counter-body">{paragraph1}</p>
          </div>
        </div>
        <div className="counter-div-1">
          <div className="counter-div-2">
            <h1 className="counter-h1">
              {<CountUp end={count2} duration={1} />}
            </h1>
            <h5 className="counter-h5">{header2}</h5>
            <p className="counter-body">{paragraph2}</p>
          </div>
        </div>
        <div className="counter-div-1">
          <div className="counter-div-2">
            <h1 className="counter-h1">
              {<CountUp end={count3} duration={1} />}
            </h1>
            <h5 className="counter-h5">{header3}</h5>
            <p className="counter-body">{paragraph3}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ThreeCounters.defaultProps = {
  header1: "",
  paragraph1: "",
  
  header2: "",
  paragraph2: "",
  
  header3: "",
  paragraph3: "",
}

export default ThreeCounters;