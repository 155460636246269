import { useState, useEffect } from 'react';
import { SectionProps } from '../../../utils/SectionProps';
import { Form } from 'react-bootstrap';
import CountUp from "react-countup";
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import BackgroundImage from "./../../../assets/overlay_tint_7d2d.png";
import BearImage from './../../../assets/bear-overlay.png';
import ScavImage from './../../../assets/scav-overlay.png';
import UsecImage from './../../../assets/usec-overlay.png';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const EscapeFromTarkov = () => {

  let location = useLocation();
  const [bear, setBear] = useState(0);
  const [usec, setUsec] = useState(0);
  const [scav, setScav] = useState(0);
  const [tk, setTk] = useState(0);
  const [death, setDeath] = useState(0);
  const [extract, setExtract] = useState(0);
  const [boss, setBoss] = useState(0);
  const [sniper, setSniper] = useState(0);
  const [guard, setGuard] = useState(0);
  const [rogue, setRogue] = useState(0);
  const [raider, setRaider] = useState(0);
  const [cultist, setCultist] = useState(0);
  const [position, setPosition] = useState({});

  function updateFontColor() {
    let params = new URL(document.location).searchParams;
    let theme = params.get("theme");

    if (theme) {
      if (theme === "light") {
        return "white";
      }
      if (theme === "dark") {
        return "dark";
      }
    }
  }

  useEffect(() => {
    document.body.style.background = "none";
    Array.from(document.querySelectorAll('.form-label')).forEach(
      (el) => el.classList.remove('form-label')
    );

    const socket = io("https://dev.anygamecounter.com");

    socket.on('connect', () => {
      console.log(`connected: ${socket.id}`)
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.emit("overlay", location.search.replace("?id=", ""));

    socket.on("overlay", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        setPosition({
          "left": data.tarkov_stats.page_position_left,
          "top": data.tarkov_stats.page_position_top,
          "width": data.tarkov_stats.page_position_width
        })
        setBear(data.tarkov_stats.bear);
        setUsec(data.tarkov_stats.usec);
        setScav(data.tarkov_stats.scav);
        setTk(data.tarkov_stats.tk);
        setDeath(data.tarkov_stats.death);
        setExtract(data.tarkov_stats.extract);
        setBoss(data.tarkov_stats.boss);
        setSniper(data.tarkov_stats.sniper);
        setGuard(data.tarkov_stats.guard);
        setRogue(data.tarkov_stats.rogue);
        setRaider(data.tarkov_stats.raider);
        setCultist(data.tarkov_stats.cultist);
      }
    })

    socket.on("escapefromtarkov", (data) => {
      if (data._id === location.search.replace("?id=", "")) {
        /*setPosition({
          "left": data.tarkov_stats.page_position_left,
          "top": data.tarkov_stats.page_position_top,
          "width": data.tarkov_stats.page_position_width
        })*/
        setBear(data.tarkov_stats.bear);
        setUsec(data.tarkov_stats.usec);
        setScav(data.tarkov_stats.scav);
        setTk(data.tarkov_stats.tk);
        setDeath(data.tarkov_stats.death);
        setExtract(data.tarkov_stats.extract);
        setBoss(data.tarkov_stats.boss);
        setSniper(data.tarkov_stats.sniper);
        setGuard(data.tarkov_stats.guard);
        setRogue(data.tarkov_stats.rogue);
        setRaider(data.tarkov_stats.raider);
        setCultist(data.tarkov_stats.cultist);
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='overlay-background' style={{ backgroundImage: `url(${BackgroundImage})`, left: position.left, top: position.top, width: position.width }}>
      <div style={{margin: 10}}>
        <div className="input-group">
          <img alt={'Bear'} src={BearImage} style={{width: '30px', height: '30px'}}/><span style={{opacity: 0}}>ll</span><CountUp id='overlay-label' style={{color: updateFontColor()}} className='overlay-label' start={bear} end={bear} onNewEnd={bear} duration={1} />
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <img alt={'USEC'} src={UsecImage} style={{width: '30px', height: '30px'}}/><span style={{opacity: 0}}>ll</span><CountUp id='overlay-label' style={{color: updateFontColor()}} className='overlay-label' start={usec} end={usec} onNewEnd={usec} duration={1} />
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <img alt={'Scav'} src={ScavImage} style={{width: '30px', height: '30px'}}/><span style={{opacity: 0}}>ll</span><CountUp id='overlay-label' style={{color: updateFontColor()}} className='overlay-label' start={scav} end={scav} onNewEnd={scav} duration={1} />
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Team Kills: <CountUp start={tk} end={tk} onNewEnd={tk} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Deaths: <CountUp start={death} end={death} onNewEnd={death} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Extracts: <CountUp start={extract} end={extract} onNewEnd={extract} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Boss Kills: <CountUp start={boss} end={boss} onNewEnd={boss} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Sniper Kills: <CountUp start={sniper} end={sniper} onNewEnd={sniper} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Guard Kills: <CountUp start={guard} end={guard} onNewEnd={guard} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Rogue Kills: <CountUp start={rogue} end={rogue} onNewEnd={rogue} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Raider Kills: <CountUp start={raider} end={raider} onNewEnd={raider} duration={1} /></Form.Label>
        </div>
        <div className="input-group" style={{marginTop: 3}}>
          <Form.Label id='overlay-label' style={{color: updateFontColor()}} className='overlay-label'>Cultist Kills: <CountUp start={cultist} end={cultist} onNewEnd={cultist} duration={1} /></Form.Label>
        </div>
      </div>
    </div>
  );
}

EscapeFromTarkov.propTypes = propTypes;
EscapeFromTarkov.defaultProps = defaultProps;

export default EscapeFromTarkov;