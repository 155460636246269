import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// Auth0
import { withAuthenticationRequired } from "@auth0/auth0-react";

// Views 
import Home from './views/Home';
import Stats from './views/Stats';
import StatsPublic from './views/StatsPublic';
import Api from './views/Api';
import FOF from './views/404';
import Profile from './views/Profile/Profile';
import Loading from './views/Loading';

import BrowserSource from './views/Overlays/BrowserSource';
import Disabled from './views/Overlays/Disabled';
import ApexLegends from './views/Overlays/Games/ApexLegends';
import ArkSurvivalEvolved from './views/Overlays/Games/ArkSurvivalEvolved';
import CallOfDutyModernWarfare from './views/Overlays/Games/CallOfDutyModernWarfare';
import CallOfDutyWarzone from './views/Overlays/Games/CallOfDutyWarzone';
import CsGo from './views/Overlays/Games/CsGo';
import DeathCounter from './views/Overlays/Games/DeathCounter';
import Destiny2PvE from './views/Overlays/Games/Destiny2PvE';
import Destiny2PvP from './views/Overlays/Games/Destiny2PvP';
import EscapeFromTarkov from './views/Overlays/Games/EscapeFromTarkov';
import EldenRing from './views/Overlays/Games/EldenRing';
import FallGuys from './views/Overlays/Games/FallGuys';
import Fortnite from './views/Overlays/Games/Fortnite';
import GoFundMe from './views/Overlays/Games/GoFundMe';
import HyperScape from './views/Overlays/Games/HyperScape';
import LeagueOfLegends from './views/Overlays/Games/LeagueOfLegends';
import LordsOfTheFallen from './views/Overlays/Games/LordsOfTheFallen';
import Minecraft from './views/Overlays/Games/Minecraft';
import MinecraftDungeons from './views/Overlays/Games/MinecraftDungeons';
import PubG from './views/Overlays/Games/PubG';
import REVN from './views/Overlays/Games/REVN';
import RingOfElysium from './views/Overlays/Games/RingOfElysium';
import RogueCompany from './views/Overlays/Games/RogueCompany';
import SevenDaysToDie from './views/Overlays/Games/SevenDaysToDie';
import Valorant from './views/Overlays/Games/Valorant';

type Props = {
  /*currentScroll: number,
  location: { pathname: string, search: string, hash: string },
  history: {
    action: string,
    entries: { title: string }[],
    goBack: () => void,
    goForward: () => void,
    index: number,
    length: number,
    location: { pathname: string },
    push: (string) => void,
    state: {},
    replaceState: ({}, string, string) => void,
    listen: (any) => () => void,
  },
  uri: string,
  title: string,*/
};

type PrivateRouteProps = Props & {
  component: any,
};

function PrivateRoute(props: PrivateRouteProps) {
  const { ...rest } = props;
  const returnToPage = "/profile";

  const Component = withAuthenticationRequired(props.component, {
    returnTo: returnToPage,
    onRedirecting: () => (
      <Loading returnTo={returnToPage} />
    )
  })

  return <Component {...rest} />;
}

function AppRouter(props: Props) {
  /*const {
    currentScroll,
    location: { pathname, search, hash },
    history,
    uri,
    channelClaimPermanentUri,
    title
  } = props;*/

  return (
    <React.Suspense>
      <Switch>
  
        <Route path={"/"} exact component={Home} />
        <Route path={"/api"} exact component={Api} />
        <Route path={"/stats"} exact component={Stats} />
        <Route path={"/stats/search"} exact component={StatsPublic} />

        <Route path={"/overlay/browsersource"} exact component={BrowserSource} />
        <Route path={"/overlay/disabled"} exact component={Disabled} />
        <Route path={"/overlay/undefined"} exact component={Disabled} />

        <Route path={"/overlay/apexlegends"} exact component={ApexLegends} />
        <Route path={"/overlay/arksurvivalevolved"} exact component={ArkSurvivalEvolved} />
        <Route path={"/overlay/callofdutymodernwarfare"} exact component={CallOfDutyModernWarfare} />
        <Route path={"/overlay/callofdutywarzone"} exact component={CallOfDutyWarzone} />
        <Route path={"/overlay/csgo"} exact component={CsGo} />
        <Route path={"/overlay/deathcounter"} exact component={DeathCounter} />
        <Route path={"/overlay/destiny2pve"} exact component={Destiny2PvE} />
        <Route path={"/overlay/destiny2pvp"} exact component={Destiny2PvP} />
        <Route path={"/overlay/eldenring"} exact component={EldenRing} />
        <Route path={"/overlay/fallguys"} exact component={FallGuys} />
        <Route path={"/overlay/fortnite"} exact component={Fortnite} />
        <Route path={"/overlay/gofundme"} exact component={GoFundMe} />
        <Route path={"/overlay/hyperscape"} exact component={HyperScape} />
        <Route path={"/overlay/leagueoflegends"} exact component={LeagueOfLegends} />
        <Route path={"/overlay/lordsofthefallen"} exact component={LordsOfTheFallen} />
        <Route path={"/overlay/minecraft"} exact component={Minecraft} />
        <Route path={"/overlay/minecraftdungeons"} exact component={MinecraftDungeons} />
        <Route path={"/overlay/pubg"} exact component={PubG} />
        <Route path={"/overlay/revn"} exact component={REVN} />
        <Route path={"/overlay/ringofelysium"} exact component={RingOfElysium} />
        <Route path={"/overlay/roguecompany"} exact component={RogueCompany} />
        <Route path={"/overlay/sevendaystodie"} exact component={SevenDaysToDie} />
        <Route path={"/overlay/escapefromtarkov"} exact component={EscapeFromTarkov} />
        <Route path={"/overlay/valorant"} exact component={Valorant} />

        <PrivateRoute path={"/profile"} exact component={Profile} />
        
        <Route path="/*" component={FOF} />
      </Switch>
    </React.Suspense>
  );
}

export default withRouter(AppRouter);