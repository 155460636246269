import { useEffect } from 'react';
import { SectionProps } from './../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Disabled = () => {

  useEffect(() => {
    document.body.style.background = "none";
  })

  return (
    <>
    </>
  );
}

Disabled.propTypes = propTypes;
Disabled.defaultProps = defaultProps;

export default Disabled;