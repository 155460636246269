import React from "react";
import { SectionProps } from '../utils/SectionProps';
import { Form, Image, Button } from 'react-bootstrap';

type Props = {
  formAction: URL,
  imageSrc: URL,
  _id: String,
  positionLeft: String,
  positionLeftPlaceholder: String,
  positionTop: String,
  positionTopPlaceholder: String,
  gofundmeUrl: URL,
  gofundmeUrlPlaceholder: String,
  buttonValue: String,
}

const OverlayPositionContainer = (props: Props) => {
  const {
    formAction,
    imageSrc,
    _id,
    positionLeft,
    positionLeftPlaceholder,
    positionTop,
    positionTopPlaceholder,
    positionWidth,
    positionWidthPlaceholder,
    gofundmeUrl,
    gofundmeUrlPlaceholder,
    buttonValue,
  } = props;

  return (
    <>
      <div className="container-med" style={{padding: "5px"}}>
        <iframe title="hiddenFrame" name="hiddenFrame" width="0" height="0" border="0" style={{display: 'none'}}></iframe>
        <Form name="myform" action={formAction} method="post" target="hiddenFrame">
          <Form.Control type="text" name="UserId" defaultValue={_id} hidden />
          <Image src={imageSrc} />
          <div className="col-sm-10">
            <div>
              <Form.Label className="input-group-addon padding">Left:</Form.Label>
              <Form.Control type="number" name="page_position_left" className="input-group-addon" defaultValue={positionLeft} placeholder={positionLeftPlaceholder} />
            </div>
            <div>
              <Form.Label className="input-group-addon padding">Top:</Form.Label>
              <Form.Control type="number" name="page_position_top" className="input-group-addon" defaultValue={positionTop} placeholder={positionTopPlaceholder} />
            </div>
            <div>
              <Form.Label className="input-group-addon padding">Width:</Form.Label>
              <Form.Control type="number" name="page_position_width" className="input-group-addon" defaultValue={positionWidth} placeholder={positionWidthPlaceholder} />
            </div>
          </div>
          {gofundmeUrl && (
            <div className="col-sm-10">
              <Form.Label className="input-group-addon padding">Gofundme URL:</Form.Label>
              <Form.Control type="text" className="input-group-addon" defaultValue={gofundmeUrl} placeholder={gofundmeUrlPlaceholder} />
            </div>
          )}
          <div id="submitbutton">
            <Button className="input-group-addon" as="input" type="submit" name="button" value={buttonValue} />
          </div>
        </Form>
      </div>
    </>
  );
};

export default OverlayPositionContainer;