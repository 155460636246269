import React from "react";
import { SectionProps } from './../../utils/SectionProps';
import { useAuth0 } from "@auth0/auth0-react";
import AuthLoginButton from '../Auth0/Auth0_LoginButton';
import AuthLogoutButton from '../Auth0/Auth0_LogoutButton';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Auth0_Header = () => {
  
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Authenticating...</div>;
  }

  return (
    <>
    {isAuthenticated === false ? (
      <ul
        className="list-reset header-nav-right"
        >
        <li>
          <AuthLoginButton/>
        </li>
      </ul>
    ) : (
      <ul
        className="list-reset header-nav-right"
        >
        <li>
          <AuthLogoutButton/>
        </li>
        <li>
          <Link className="profile" to="/profile">Profile</Link>
        </li>
      </ul>
    )}
    </>
  );
};

Auth0_Header.propTypes = propTypes;
Auth0_Header.defaultProps = defaultProps;

export default Auth0_Header;